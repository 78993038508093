<template>
  <div>
    <template>
      <v-data-table
        :headers="headers"
        :items="logs"
        :items-per-page="5"
        :options.sync="options"
        class="elevation-1"
      ></v-data-table>
    </template>
  </div>
</template>
<script>
export default {
  name: "lead-logs",
  data() {
    return {
        logs:[],
        loading:false,
        options:{},
        headers:[
            {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "createAt",
          
        },
        ]
    }
  },
  watch:{
    options(val) {
      if (val != {}) {
        this.getLeadLogs();
      }
    },
  },
  methods:{
    getLeadLogs(){
        //
    }
  }
};
</script>
<style lang="scss" scoped></style>
